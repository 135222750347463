/* eslint-disable react/jsx-key */
import * as React from "react";

import S from "./Logos.styled";

export interface ILogos {
  list: {
    image: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const Logos: React.FC<ILogos> = (data) => {
  return (
    <S.Grid>
      <S.MainInner>
        {data.list.map((i, index) => {
          return (
            <S.Item key={index}>
              <S.Image
                src={data.list[index].image.sourceUrl}
                alt={data.list[index].image.altText}
              />
            </S.Item>
          );
        })}
      </S.MainInner>
    </S.Grid>
  );
};

export default Logos;
