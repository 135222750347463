import * as React from "react";

import { LocationContext } from "@/contexts/LocationProvider";

import S from "./Header.style";
import useHeaderData from "./useHeaderData";

type Props = {
  hero: boolean;
};

const Header: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const data = useHeaderData();
  const location = React.useContext(LocationContext);
  const [scrollPosition, setScrollPosition] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrolled = !!scrollPosition || !props.hero;

  return (
    <S.Header isOpen={isOpen || scrolled}>
      {data.logo && (
        <S.HomeLogo href={data.repeater[0].link?.url}>
          <S.Logo
            src={
              isOpen || scrolled ? data.logo.sourceUrl : data.logoBlue.sourceUrl
            }
            alt={isOpen || scrolled ? data.logo.altText : data.logoBlue.altText}
          />
        </S.HomeLogo>
      )}
      <S.Hamburger onClick={() => setIsOpen(!isOpen)}>
        <S.HamburgerLine />
        <S.HamburgerLine />
        <S.HamburgerLine />
      </S.Hamburger>
      <S.Container isOpen={isOpen}>
        {data.repeater?.map(({ link }, index) => {
          const isActive =
            link.url.indexOf("work") !== -1
              ? location?.pathname?.indexOf("case") !== -1 ||
                location?.pathname === link.url
              : location?.pathname === link.url;

          return (
            <S.Link
              key={index}
              href={link?.url}
              target={link?.target}
              isActive={isActive}
            >
              {link?.title}
            </S.Link>
          );
        })}
      </S.Container>
    </S.Header>
  );
};
export default Header;
