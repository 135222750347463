import * as React from "react";

import S from "@/components/layout-blocks/Cases/Cases.styled";

import { useCasesData } from "./useCasesData";

export interface ICases {}

const Cases: React.FC<ICases> = () => {
  const data = useCasesData();

  return (
    <S.Container>
      {(data || []).map((c) => (
        <S.Cases key={c.id} to={c.uri} color="#FAF7CB">
          {c.featuredImage && (
            <S.Overlay imageUrl={c.featuredImage.node.sourceUrl}></S.Overlay>
          )}
          <S.LinkText>{c.title}</S.LinkText>
        </S.Cases>
      ))}
    </S.Container>
  );
};

export default Cases;
