import { createGlobalStyle } from "styled-components";

import Eksell from "../../src/fonts/EksellDisplayWeb-Large.woff";
import OrangeKid from "../fonts/OrangeKid.ttf";
import JostRegular from "../fonts/Jost-Regular.ttf";
import JostItalic from "../fonts/Jost-Italic.ttf";
import JostMedium from "../fonts/Jost-Medium.ttf";
import JostMediumItalic from "../fonts/Jost-MediumItalic.ttf";
import PolyRegular from "../fonts/Poly-Regular.ttf";
import PolyItalic from "../fonts/Poly-Italic.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Eksell';
    src: url(${Eksell});
    font-weight: 300;
  }

  @font-face {
    font-family: 'Orange Kid';
    src: url(${OrangeKid});
  }

  @font-face {
    font-family: 'Jost';
    font-weight: 400;
    font-style: normal;
    src: url(${JostRegular})
  }

  @font-face {
    font-family: 'Jost';
    font-weight: 400;
    font-style: italic;
    src: url(${JostItalic})
  }

  @font-face {
    font-family: 'Jost';
    font-weight: 500;
    font-style: normal;
    src: url(${JostMedium})
  }

  @font-face {
    font-family: 'Jost';
    font-weight: 500;
    font-style: italic;
    src: url(${JostMediumItalic})
  }

  @font-face {
    font-family: 'Poly';
    font-weight: 400;
    font-style: normal;
    src: url(${PolyRegular})
  }

  @font-face {
    font-family: 'Poly';
    font-weight: 400;
    font-style: italic;
    src: url(${PolyItalic})
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poly", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
  }
  body {
      background-color: ${(props) => props.theme.backgroundColors.primary};
  }
`;

export default GlobalStyle;
