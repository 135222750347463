import styled from "styled-components";

const Body = styled.div`
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const S = {
  Body,
};

export default S;
