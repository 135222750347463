import { detailsSmall, h3, paragraph, paragraphBig } from "@/styles/typography";
import styled from "styled-components";
import { detailsBig } from "@/styles/typography";

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;
  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-gap: 20px;
    margin-top: 20px;
  }
`;

const GridItem = styled.div`
  position: relative;
  --grid-item-hover-opacity: 0;

  &:hover {
    --grid-item-hover-opacity: 1;
  }

  &:nth-of-type(1) {
    grid-column: 1 / span 2;
  }
  &:nth-of-type(2) {
    grid-column: 3 / span 2;
  }
  &:nth-of-type(3) {
    grid-column: 1 / span 4;
  }
  &:nth-of-type(4) {
    grid-column: 1 / span 2;
    grid-row: span 2;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 4;
    }
  }
  &:nth-of-type(5) {
    grid-column: 3 / span 1;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 2;
    }
  }
  &:nth-of-type(6) {
    grid-column: 4 / span 1;
    @media only screen and (max-width: 992px) {
      grid-column: 3 / span 2;
    }
  }
  &:nth-of-type(7) {
    grid-column: 3 / span 2;
    grid-row: 4;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 4;
      grid-row: auto;
    }
  }
  &:nth-of-type(8) {
    grid-column: 1 / span 2;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 4;
    }
  }
  &:nth-of-type(9) {
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 2;
    }
  }
  &:nth-of-type(10) {
    @media only screen and (max-width: 992px) {
      grid-column: 3 / span 2;
    }
  }
  &:nth-of-type(11) {
    grid-column: 1 / span 2;
    padding: 40px;
    background-color: ${(props) => props.theme.factColors.tertiary};
    display: flex;
    flex-direction: column;
    height: 100%;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 4;
    }
  }
  &:nth-of-type(12) {
    grid-column: 3 / span 2;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 4;
    }
    img {
      height: 100%;
    }
  }
`;

const GridItemHover = styled(detailsBig)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(20, 24, 44, 0.61);
  opacity: var(--grid-item-hover-opacity);
  color: #ffffff;
  text-align: left;
  padding: 20px;
  transition: all 0.3s ease;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const GridImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

const Header = styled(h3)`
  margin-bottom: 15px;
  text-transform: uppercase;
`;

const Text = styled(paragraphBig)`
  margin-bottom: auto;
`;

const ButtonContainer = styled.button`
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.textColors.primary};
  background-color: transparent;
  margin: auto auto 0;
  display: flex;
  align-items: center;
  padding: 13px 24px;
  margin-top: 30px;
  &:hover {
    img {
      transform: translateX(6px);
    }
  }
`;
const ButtonText = styled(detailsSmall)``;

const ArrowNext = styled.img`
  margin-left: 10px;
  transition: all 0.2s ease;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const S = {
  Grid,
  GridItem,
  GridImage,
  GridItemHover,
  Header,
  Text,
  ButtonContainer,
  ButtonText,
  ArrowNext,
  VideoContainer,
  Video,
};

export default S;
