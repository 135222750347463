import { graphql, useStaticQuery } from "gatsby";

export type ICase = {
  id: string;
  title: string;
  uri: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
};

export type ICasesData = {
  allWpCase: {
    nodes: ReadonlyArray<ICase>;
  };
};

export const useCasesData = () => {
  const { allWpCase }: ICasesData = useStaticQuery(
    graphql`
      {
        allWpCase(sort: { order: ASC, fields: menuOrder }) {
          nodes {
            id
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    `
  );
  return allWpCase.nodes;
};
