import * as React from "react";

export interface ILocationProvider {
  href: string;
  pathname: string;
}

export const LocationContext = React.createContext<Partial<ILocationProvider>>(
  {}
);

const LocationProvider: React.FC<
  React.PropsWithChildren<ILocationProvider>
> = ({ children, href, pathname }) => {
  return (
    <LocationContext.Provider value={{ href, pathname }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
