import styled, { css } from "styled-components";

export const h1 = styled.h1`
  font-family: Eksell, Georgia, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 100px;
  line-height: 120%;
  font-size: 39px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    font-size: 70px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 100px;
  }
`;

export const h2 = styled.h2`
  font-family: Eksell, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 160%;
  @media only screen and (min-width: 600px) {
    font-size: 60px;
  }
`;

export const h3 = styled.h3`
  font-family: Eksell, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 127%;
  @media only screen and (min-width: 600px) {
    font-size: 30px;
  }
`;

export const h4 = styled.h4`
  font-family: Eksell, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
`;

export const h5 = styled.h5`
  font-family: Eksell, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
`;

export const h6 = styled.h6`
  font-family: Eksell, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
`;

export const paragraph = styled.p`
  font-family: Poly, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
`;

export const paragraphBigCss = css`
  font-family: Poly, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 174%;
  @media only screen and (min-width: 600px) {
    font-size: 23px;
  }
`;

export const paragraphBig = styled.p`
  ${paragraphBigCss}
`;

export const detailsSmallCss = css`
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 176%;
  letter-spacing: 0.135em;
  text-transform: uppercase;
`;

export const detailsSmall = styled.p`
  ${detailsSmallCss}
`;

export const detailsBig = styled.p`
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.135em;
  text-transform: uppercase;
  @media ${({ theme }) => theme.breakpoints.xs} {
    font-size: 16px;
  }
`;
