import { IImage } from "@/src/types";
import * as React from "react";
import { IVideo } from "@/src/types";
import S from "./GridThreeImages.styled";

type Item = {
  image: IImage;
  video?: IVideo;
  link?: {
    title: string;
    target: string;
    url: string;
  };
};

export interface IGridThreeImages {
  items: Item[];
}

const GridThreeImages: React.FC<IGridThreeImages> = (data) => {
  return (
    <>
      <S.Grid>
        {(data.items || []).map((item, index) => (
          <S.GridItem key={index}>
            {item.link ? (
              <a href={item.link?.url || "/"} target={item.link.target}>
                {item.video ? (
                  <S.VideoContainer>
                    <S.Video autoPlay muted loop playsInline>
                      <source
                        src={item.video.mediaItemUrl}
                        type={item.video.mimeType}
                      />
                    </S.Video>
                  </S.VideoContainer>
                ) : (
                  <S.GridImage
                    src={item.image.sourceUrl}
                    alt={item.image.altText}
                  />
                )}
                {item.link && (
                  <S.GridItemHover>{item.link.title}</S.GridItemHover>
                )}
              </a>
            ) : (
              <>
                {item.video ? (
                  <S.VideoContainer>
                    <S.Video autoPlay muted loop playsInline>
                      <source
                        src={item.video.mediaItemUrl}
                        type={item.video.mimeType}
                      />
                    </S.Video>
                  </S.VideoContainer>
                ) : (
                  <S.GridImage
                    src={item.image.sourceUrl}
                    alt={item.image.altText}
                  />
                )}
              </>
            )}
          </S.GridItem>
        ))}
      </S.Grid>
    </>
  );
};

export default GridThreeImages;
