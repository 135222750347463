import { graphql, useStaticQuery } from "gatsby";

export type IHeaderData = {
  wp: {
    siteSettings: {
      header: {
        logo: {
          sourceUrl: string;
          altText: string;
        };
        logoBlue: {
          sourceUrl: string;
          altText: string;
        };
        repeater: {
          link: {
            url: string;
            title: string;
            target: string;
          };
        }[];
      };
    };
  };
};

const useHeaderData = () => {
  const { wp }: IHeaderData = useStaticQuery(
    graphql`
      {
        wp {
          siteSettings {
            header {
              logo {
                sourceUrl
                altText
              }
              logoBlue {
                sourceUrl
                altText
              }
              repeater {
                link {
                  url
                  title
                  target
                }
              }
            }
          }
        }
      }
    `
  );
  return wp.siteSettings.header;
};
export default useHeaderData;
