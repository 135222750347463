import styled from "styled-components";

import { detailsBig, h1 } from "@/styles/typography";

const MainHero = styled.div`
  padding-top: 120px;
  padding-bottom: 30px;
  background: #d9e7cd;
  height: 400px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-top: 150px;
    padding-bottom: 70px;
    height: 690px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    height: 750px;
  }
`;
const MainInner = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding: 0 100px;
  }
`;
const TextContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HeadingRowOne = styled(h1)`
  color: #fff;
  margin-bottom: 8px;

  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-bottom: 0;
  }
`;
const HeadingRowOneAnimate = styled(h1)`
  color: #fff;
  margin-bottom: 8px;

  //Adding animation
  animation-name: yellowtitle;
  animation-duration: 10s;
  animation-delay: 0.2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;

  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-bottom: 0;
  }
  @keyframes yellowtitle {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    49% {
      opacity: 1;
    }
    51% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const HeadingRowOneAnimateCopy = styled(h1)`
  color: #fff;
  margin-bottom: 8px;

  //Making the copy title appear in the same place as the original title
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  //Adding the same animation but with a delay
  animation-name: yellowtitle;
  animation-duration: 10s;
  animation-delay: 5.2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-bottom: 0;
  }
`;

const BlackHeaderOne = styled(h1)`
  color: ${(props) => props.theme.textColors.primary};
  animation-name: blacktitle;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-delay: 0.7s;
  animation-fill-mode: both;
  @keyframes blacktitle {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    49% {
      opacity: 1;
    }
    51% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const BlackHeaderOneCopy = styled(h1)`
  color: ${(props) => props.theme.textColors.primary};

  //Making the copy title appear in the same place as the original title
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  //Adding the same animation but with a delay
  animation-name: yellowtitle;
  animation-duration: 10s;
  animation-delay: 5.7s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
`;

const BlackHeaderTwo = styled(h1)`
  color: ${(props) => props.theme.textColors.primary};
`;

const CircleHeaderContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 8px;
  position: relative;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-top: 0;
  }
`;

const CircleImage = styled.img`
  width: 22px;
  height: 22px;
  position: absolute;
  transform: translateX(-28px);
  animation-name: circle;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes circle {
    0% {
      transform: translateX(-28px) rotate(0deg);
    }
    100% {
      transform: translateX(-28px) rotate(360deg);
    }
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 69px;
    height: 69px;
    transform: translateX(-85px);
    @keyframes circle {
      0% {
        transform: translateX(-85px) rotate(0deg);
      }
      100% {
        transform: translateX(-85px) rotate(360deg);
      }
    }
  }
`;

const SmallText = styled(detailsBig)`
  color: ${(props) => props.theme.textColors.primary};
  margin-top: 25px;
  margin-bottom: 25px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-top: 29px;
    margin-bottom: 40px;
  }
`;

const LineImage = styled.img`
  width: 30px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 63px;
  }
`;

const LinkTextContainer = styled.div`
  background: ${(props) => props.theme.textColors.secondary};
`;

const LinkText = styled.p`
  font-family: "Jost", "sans-serif";
  font-style: italic;
  line-height: 120%;
  font-size: 13.5px;
  text-align: left;
  display: none;
  @media ${({ theme }) => theme.breakpoints.md} {
    display: block;
    font-size: 23px;
    text-align: center;
    padding: 27px 20px 30px;
  }
`;

const MobileText = styled.div`
  padding: 15px 20px 16px;
  text-align: center;
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding: 27px 20px 30px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
  p,
  a {
    font-family: "Jost", "sans-serif";
    font-style: italic;
    line-height: 130%;
    font-size: 13.5px;
    @media ${({ theme }) => theme.breakpoints.xs} {
      font-size: 23px;
    }
  }
  & a {
    color: ${(props) => props.theme.textColors.primary};
    font-size: inherit;
    position: relative;
    display: inline-block;
    text-decoration: none;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 0.7px;
      background: ${(props) => props.theme.textColors.primary};
      bottom: 0;
      transition: all 0.2s ease;
      @media ${({ theme }) => theme.breakpoints.sm} {
        height: 1.5px;
      }
    }
    &:hover::after {
      bottom: 50%;
    }
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.textColors.primary};
  font-style: italic;
  font-family: "Jost", sans-serif;
  font-size: inherit;
  position: relative;
  display: inline-block;
  text-decoration: none;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.textColors.primary};
    bottom: 0;
    transition: all 0.2s ease;
    @media ${({ theme }) => theme.breakpoints.sm} {
      height: 1.5px;
    }
  }
  &:hover::after {
    bottom: 50%;
  }
`;

const HeartImage = styled.img`
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 100%;
    max-width: 455px;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 80%;
    max-width: 350px;
    margin-top: 30px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 100%;
    max-width: 455px;
    border: 0;
  }
`;

const ContainerText = styled.div`
  position: relative;
`;

const SloganText = styled.p`
  font-family: "Jost", "sans-serif";
  font-style: italic;
  line-height: 120%;
  font-size: 13px;
  text-align: center;
  padding: 15px 20px 16px;
  width: 300px;
  margin: 0 auto;

  @media ${({ theme }) => theme.breakpoints.xs} {
    font-size: 23px;
    padding: 27px 20px 30px;
    width: 100%;
  }
`;

const PenImage = styled.img`
  width: 90%;
  margin-top: 53px;
  margin-bottom: 48px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 100%;
    max-width: 916px;
    margin-top: 85px;
    margin-bottom: 90px;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-bottom: 65px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    margin-bottom: 90px;
  }
`;

const S = {
  MainHero,
  MainInner,
  HeadingRowOne,
  HeadingRowOneAnimate,
  HeadingRowOneAnimateCopy,
  TextContainer,
  BlackHeaderOne,
  BlackHeaderOneCopy,
  BlackHeaderTwo,
  CircleImage,
  CircleHeaderContainer,
  SmallText,
  LineImage,
  LinkTextContainer,
  LinkText,
  Link,
  HeartImage,
  ContainerText,
  SloganText,
  PenImage,
  MobileText,
};

export default S;
