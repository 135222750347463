import styled from "styled-components";

import {
  detailsSmall,
  detailsSmallCss,
  h2,
  paragraph,
} from "@/styles/typography";

const TestiMonials = styled.div`
  background-color: ${(props) => props.theme.factColors.primary};
  padding: 60px 0;
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding: 100px 0;
  }
`;

const InnerDiv = styled.div`
  text-align: center;
  @media ${({ theme }) => theme.breakpoints.xs} {
    text-align: left;
  }
`;
const Header = styled(h2)`
  color: ${(props) => props.theme.textColors.primary};
  margin-bottom: 16px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 100px;
  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0;
  }
`;

const MainInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;

  .swiper {
    padding: 0 20px;
    @media ${({ theme }) => theme.breakpoints.xs} {
      padding: 0 100px;
    }
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0;
  }

  .swiper-button-prev {
    left: 0;
    bottom: 50px;
    top: auto;
    @media ${({ theme }) => theme.breakpoints.xs} {
      bottom: 50%;
      transform: translateY(50%);
    }
    @media ${({ theme }) => theme.breakpoints.md} {
      left: 100px;
    }
  }
  .swiper-button-next {
    right: 0;
    bottom: 50px;
    top: auto;
    @media ${({ theme }) => theme.breakpoints.xs} {
      bottom: 50%;
      transform: translateY(50%);
    }
    @media ${({ theme }) => theme.breakpoints.md} {
      right: 100px;
    }
  }
`;

const SwiperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.xs} {
    flex-direction: row;
    max-width: 1000px;
    margin: 0 auto;
    width: 90%;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

const Intro = styled.span`
  ${detailsSmallCss}
  display: inline-block;
  margin-right: 4px;
`;

const TestiMonial = styled(paragraph)`
  text-align: center;
  max-width: 80%;
  color: ${(props) => props.theme.textColors.primary};
  @media ${({ theme }) => theme.breakpoints.xs} {
    text-align: left;
    padding-left: 70px;
    max-width: 800px;
  }
`;

const Image = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 100%;
  margin-bottom: 13px;
  object-fit: cover;
  object-position: center;
  filter: grayscale(1);
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 111px;
    height: 111px;
  }
`;

const Author = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.textColors.primary};
  border-bottom: 1px solid ${(props) => props.theme.textColors.primary};
  padding-bottom: 1px;
`;

const Chapter = styled.p`
  font-style: italic;
  margin-top: 6px;
`;

const NextButton = styled.svg`
  position: absolute;
  right: 0;
  top: 100px;
  cursor: pointer;
  z-index: 100;
  height: 30px;
  width: 40px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    height: 200px;
    width: 40px;
    right: 20px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 100px;
  }
  path {
    stroke-width: 4;
    @media ${({ theme }) => theme.breakpoints.xs} {
      stroke-width: 2;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
      stroke-width: 1;
    }
  }
`;

const PrevButton = styled.svg`
  position: absolute;
  left: 0;
  top: 100px;
  cursor: pointer;
  z-index: 100;
  height: 30px;
  width: 40px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    height: 200px;
    width: 40px;
    left: 20px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 100px;
  }
  path {
    stroke-width: 4;
    @media ${({ theme }) => theme.breakpoints.xs} {
      stroke-width: 2;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
      stroke-width: 1;
    }
  }
`;

const S = {
  TestiMonials,
  Header,
  MainInner,
  SwiperContent,
  Intro,
  TestiMonial,
  Image,
  Author,
  Content,
  InnerDiv,
  Chapter,
  NextButton,
  PrevButton,
};
export default S;
