import styled from "styled-components";

const Header = styled.div<{ isOpen: boolean }>`
  justify-content: space-between;
  align-items: center;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 60px;
  padding: 10px 20px;
  height: ${({ isOpen, theme }) => (isOpen ? theme.colors.primary : "80px")};

  background: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.primary : "transparent"};
  --text-color: ${({ isOpen, theme }) =>
    isOpen ? "#ffffff" : theme.textColors.primary};
  transition: all ease 0.8s;
  @media ${({ theme }) => theme.breakpoints.sm} {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    padding: 0 20px;
  }
`;

const Hamburger = styled.div`
  @media ${({ theme }) => theme.breakpoints.xs} {
    display: none;
  }
`;

const HamburgerLine = styled.hr`
  width: 36px;
  border-top: 1px;
  border-style: solid;
  margin-bottom: 9px;
  border-color: var(--text-color);
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Container = styled.div<{ isOpen: boolean }>`
  gap: 33px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  padding: 8px;
  padding-bottom: 20px;
  align-items: center;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.primary : "transprent"};
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding: 8px;
    position: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 13px;
  }
`;

const Logo = styled.img`
  height: 32px;
  @media ${({ theme }) => theme.breakpoints.md} {
    height: 40px;
  }
`;

const Link = styled.a<{ isActive?: boolean }>`
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 23px;
  letter-spacing: 0.135em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-size: 16px;
  text-decoration: ${({ isActive }) => (isActive ? "line-through" : "none")};
  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-left: 32px;
    &:hover {
      text-decoration: underline;
    }
    &:not(:last-of-type) {
      &:after {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 9999px;
        margin-left: 20px;
      }
    }
  }
`;

const HomeLogo = styled.a`
  cursor: pointer;
  text-align: left;
`;

const S = {
  Header,
  Container,
  Logo,
  Link,
  Hamburger,
  HamburgerLine,
  HomeLogo,
};

export default S;
