import * as React from "react";
import Cases, { ICases } from "@/components/layout-blocks/Cases";
import CasesGrid, { ICasesGrid } from "@/components/layout-blocks/CasesGrid";
import ContactGrid, {
  IContactGrid,
} from "@/components/layout-blocks/ContactGrid";
import Grid, { IGrid } from "@/components/layout-blocks/Grid";
import Logos, { ILogos } from "@/components/layout-blocks/Logos";
import MainHero, { IMainHero } from "@/components/layout-blocks/MainHero";
import TestiMonial, {
  ITestiMonial,
} from "@/components/layout-blocks/TestiMonial";
import GridTwoImages, {
  IGridTwoImages,
} from "@/components/layout-blocks/GridTwoImages";
import GridOneLargeImage, {
  IGridOneLargeImage,
} from "@/components/layout-blocks/GridOneLargeImage";
import GridFourImages, {
  IGridFourImages,
} from "@/components/layout-blocks/GridFourImages";
import GridThreeImages, {
  IGridThreeImages,
} from "@/components/layout-blocks/GridThreeImages";

export enum BlockType {
  MainHero,
  Cases,
  Grid,
  ContactGrid,
  TestiMonial,
  Logos,
  CasesGrid,
  GridTwoImages,
  GridOneLargeImage,
  GridFourImages,
  GridThreeImages,
}

export type IComponentTypes =
  | typeof MainHero
  | typeof Grid
  | typeof TestiMonial
  | typeof Logos
  | typeof Cases
  | typeof CasesGrid
  | typeof ContactGrid
  | typeof GridTwoImages
  | typeof GridOneLargeImage
  | typeof GridFourImages
  | typeof GridThreeImages;

export interface IComponent
  extends IMainHero,
    IGrid,
    ITestiMonial,
    ILogos,
    ICases,
    ICasesGrid,
    IContactGrid,
    IGridTwoImages,
    IGridOneLargeImage,
    IGridFourImages,
    IGridThreeImages {
  label: keyof typeof BlockType;
}
export interface IFlexible {
  components: ReadonlyArray<IComponent>;
}

const BlockComponents: {
  [C in BlockType]: IComponentTypes;
} = {
  [BlockType.MainHero]: MainHero,
  [BlockType.Grid]: Grid,
  [BlockType.Cases]: Cases,
  [BlockType.TestiMonial]: TestiMonial,
  [BlockType.Logos]: Logos,
  [BlockType.CasesGrid]: CasesGrid,
  [BlockType.ContactGrid]: ContactGrid,
  [BlockType.GridTwoImages]: GridTwoImages,
  [BlockType.GridOneLargeImage]: GridOneLargeImage,
  [BlockType.GridFourImages]: GridFourImages,
  [BlockType.GridThreeImages]: GridThreeImages,
};

const Flexible: React.FC<IFlexible> = ({ components }) => {
  return (
    <>
      {components
        ?.filter(
          (c) => c.label !== undefined && BlockType[c.label] !== undefined
        )
        .map((comp, index) => {
          const Component = BlockComponents[BlockType[comp.label]];

          return <Component key={index} {...comp} />;
        })}
    </>
  );
};

export default Flexible;
