import * as React from "react";
import { Helmet } from "react-helmet";

export interface ISeo {
  metaDesc?: string;
  opengraphTitle: string;
  opengraphAuthor?: string;
  opengraphImage: {
    sourceUrl: string;
  };
  lang?: string;
  meta?: Array<{ name: string; content: string }>;
}

const Seo: React.FC<ISeo> = ({
  metaDesc = "",
  opengraphTitle = "",
  opengraphAuthor = "",
  opengraphImage,
  lang = "en",
  meta = [],
}) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={opengraphTitle}
      meta={[
        {
          name: `description`,
          content: metaDesc,
        },
        {
          property: `og:image`,
          content: opengraphImage?.sourceUrl,
        },
        {
          property: `og:title`,
          content: opengraphTitle,
        },
        {
          property: `og:description`,
          content: metaDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: opengraphAuthor,
        },
        {
          name: `twitter:title`,
          content: opengraphTitle,
        },
        {
          name: `twitter:description`,
          content: metaDesc,
        },
      ].concat(meta)}
    />
  );
};

export default Seo;
