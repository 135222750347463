import styled from "styled-components";

import { h3, paragraphBig } from "@/styles/typography";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-gap: 20px;
    margin: 0 0 20px 0;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
  }
`;

const GridItem = styled.div`
  position: relative;
  width: 100%;
  grid-column: span 2;
  &:nth-of-type(1) {
    @media ${({ theme }) => theme.breakpoints.md} {
      grid-column: 1 / span 2;
    }
  }
  &:nth-of-type(2) {
    background: ${(props) => props.theme.factColors.secondary};
    padding: 20px;
    position: relative;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
      @media ${({ theme }) => theme.breakpoints.sm} {
        display: none;
      }
      @media ${({ theme }) => theme.breakpoints.md} {
        display: block;
      }
    }
    @media ${({ theme }) => theme.breakpoints.md} {
      grid-column: 1;
      grid-row: 2;
    }
  }
  &:nth-of-type(3) {
    background: ${(props) => props.theme.factColors.primary};
    padding: 60px 20px;
    @media ${({ theme }) => theme.breakpoints.md} {
      grid-column: 2;
      grid-row: 2;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
  &:nth-of-type(4) {
    @media ${({ theme }) => theme.breakpoints.sm} {
      grid-column: span 1;
      grid-row: unset;
    }
  }
  &:nth-of-type(5) {
    grid-row: 4;
    @media ${({ theme }) => theme.breakpoints.sm} {
      grid-column: span 1;
      grid-row: unset;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;
const SmallImage = styled.img`
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const ImageLine = styled.div`
  width: 20px;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const MobileImage = styled.img`
  @media only screen and (max-width: 992px) {
    width: 100%;
    display: block;
    object-fit: cover;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 100%;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`;

const PenImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  object-fit: contain;
  padding: 60px 20px;
  @media ${({ theme }) => theme.breakpoints.sm} {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    max-width: 400px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 450px;
    max-height: 450px;
    padding: 0;
  }
`;

const SmallBox = styled.div`
  padding: 60px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.color};
  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 40px;
    height: 100%;
  }
`;
const Text = styled(paragraphBig)`
  width: 92%;
  margin: 0 auto;
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 90%;
  }
`;

const Text2 = styled(paragraphBig)`
  margin: 0 auto;
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 75%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${({ theme }) => theme.breakpoints.md} {
    justify-content: start;
  }
`;

const Line = styled.p`
  padding: 16px;
  font-size: 23px;
  font-family: "Poly";
`;

const Header = styled(h3)`
  margin-bottom: 15px;
`;
const ButtonContainer = styled.button`
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.textColors.primary};
  background-color: transparent;
  margin: auto auto 0;
  display: flex;
  align-items: center;
  padding: 13px 24px;
  margin-top: 30px;
  &:hover {
    img {
      transform: translateX(6px);
    }
  }
`;
const ButtonText = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 176%;
  letter-spacing: 0.135em;
  text-transform: uppercase;
  color: ${(props) => props.theme.textColors.primary};
`;

const ArrowNext = styled.img`
  margin-left: 10px;
  transition: all 0.2s ease;
`;

const S = {
  Grid,
  GridItem,
  Image,
  PenImage,
  Text,
  Text2,
  Line,
  TextContainer,
  SmallBox,
  Header,
  ButtonContainer,
  ButtonText,
  ArrowNext,
  SmallImage,
  MobileImage,
  ImageLine,
};

export default S;
