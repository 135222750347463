import styled from "styled-components";

import { detailsSmall, h3 } from "@/styles/typography";

const Footer = styled.div`
  background-color: ${(props) => props.theme.backgroundColors.secondary};
  display: flex;
  justify-content: center;
  padding: 60px 0;
`;

const MainInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  color: ${(props) => props.theme.textColors.secondary};
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.xs} {
    justify-content: space-between;
    padding: 0 40px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    display: flex;
  }
`;

const FlexContainer = styled.div`
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.md} {
    :first-child {
      width: 33%;
    }
    :nth-child(2) {
      width: 100%;
    }
  }
  :nth-child(2) {
    @media ${({ theme }) => theme.breakpoints.sm} {
      display: flex;
      justify-content: space-between;
    }
    @media ${({ theme }) => theme.breakpoints.lg} {
      justify-content: flex-end;
    }
  }
`;

const QuoteContainer = styled.div`
  text-align: left;
  margin-bottom: 60px;
  width: 100%;
`;

const QuoteAuthor = styled.p`
  font-style: italic;
  font-size: 16px;
  font-family: "Poly", "sans-serif";
  margin-top: 23px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-top: 36px;
  }
`;

const NewsLetterContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;
  margin-bottom: 60px;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 40%;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 33%;
  }
`;

const FollowContactWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 50%;
  }
`;

const FollowContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  width: 50%;
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: auto;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  width: 50%;
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: auto;
  }
`;

const Header = styled(h3)`
  margin-bottom: 20px;
  font-size: 22px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-bottom: 10px;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 30px; // Back to original h3 styling
  }
`;
const SecondHeader = styled.p`
  display: none;
  @media ${({ theme }) => theme.breakpoints.xs} {
    display: block;
    font-size: 16px;
    font-family: "Jost", "sans-serif";
    margin-bottom: 36px;
    font-weight: bold;
  }
`;

const FormText = styled.p`
  display: block;
  font-size: 16px;
  font-family: "Jost", "sans-serif";
  margin-bottom: 16px;
  font-weight: bold;
`;

const ErrorMessage = styled(FormText)`
  color: #5e0000;
`;

const Label = styled.label`
  font-family: "Poly", "sans-serif";
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  display: block;
  appearance: none;
  background: transparent;
  border: 1px solid ${(props) => props.theme.textColors.secondary};
  height: 24px;
  width: 24px;
  margin-right: 11px;
  cursor: pointer;
  border-radius: 4px;

  :checked {
    background-color: ${(props) => props.theme.factColors.secondary};
  }
`;

const InputField = styled.input`
  width: 100%;
  height: 46px;
  margin-bottom: 10px;
  background: none;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.textColors.secondary};
  font-family: "Poly", "sans-serif";
  font-size: 16px;
  color: ${(props) => props.theme.textColors.secondary};
  border-radius: 4px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const Button = styled.button`
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  background: none;
  border: 1px solid ${(props) => props.theme.textColors.secondary};
  color: ${(props) => props.theme.textColors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 24px;
  height: 46px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 190px;
  }
  &:hover {
    img {
      transform: translateX(6px);
    }
  }
`;

const ButtonText = styled(detailsSmall)``;

const ArrowNext = styled.img`
  margin-left: 10px;
  transition: all 0.2s ease;
`;

const SocialLink = styled.a`
  font-family: "Poly", "sans-serif";
  font-size: 16px;
  color: ${(props) => props.theme.textColors.secondary};
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;
const Link = styled.a`
  font-family: "Poly", "sans-serif";
  font-size: 16px;
  color: ${(props) => props.theme.textColors.secondary};
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 11px;
  margin-top: 2px;
  &:hover {
    text-decoration: underline;
  }
`;
const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5%;
`;

const FirstFooterImage = styled.img`
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 164px;
    height: 185px;
    margin-left: 35%;
    margin-top: 15%;
    position: relative;
    transform: translateX(-50%);
  }
`;

const FooterImage = styled.img`
  display: none;
  width: 164px;
  height: 185px;
  margin-left: 35%;
  margin-top: 15%;
  position: relative;
  transform: translateX(-50%);

  @media ${({ theme }) => theme.breakpoints.xs} {
    display: block;
  }
`;

const MobileFooterImage = styled.img`
  max-width: 200px;
  max-height: 200px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 46px;

  @media ${({ theme }) => theme.breakpoints.xs} {
    display: none;
  }
`;

const S = {
  Footer,
  MainInner,
  FlexContainer,
  QuoteContainer,
  NewsLetterContainer,
  FollowContactWrapper,
  FollowContainer,
  ContactContainer,
  Header,
  SecondHeader,
  Label,
  Input,
  InputField,
  Button,
  ButtonText,
  ArrowNext,
  SocialLink,
  QuoteAuthor,
  Logo,
  FirstFooterImage,
  FooterImage,
  Link,
  FormText,
  ErrorMessage,
  MobileFooterImage,
};

export default S;
