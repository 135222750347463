import { graphql, useStaticQuery } from "gatsby";

export type IFooterData = {
  wp: {
    siteSettings: {
      footer: {
        quotetext: string;
        quoteauthor: string;
        headingone: string;
        headingtwo: string;
        headingthree: string;
        phonetext: string;
        emailtext: string;
        imageonefooter: {
          sourceUrl: string;
          altText: string;
        };
        imagetwofooter: {
          sourceUrl: string;
          altText: string;
        };
        sociallinks: {
          logo: {
            sourceUrl: string;
            altText: string;
          };
          link: {
            url: string;
            title: string;
            target: string;
          };
        }[];
      };
    };
  };
};

const useFooterData = () => {
  const { wp }: IFooterData = useStaticQuery(
    graphql`
      {
        wp {
          siteSettings {
            footer {
              quotetext
              quoteauthor
              headingone
              headingtwo
              headingthree
              emailtext
              phonetext
              sociallinks {
                logo {
                  sourceUrl
                  altText
                }
                link {
                  url
                  title
                  target
                }
              }
              imageonefooter {
                sourceUrl
                altText
              }
              imagetwofooter {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    `
  );
  return wp.siteSettings.footer;
};
export default useFooterData;
