import * as React from "react";

import S from "@/components/layout-blocks/ContactGrid/ContactGrid.styled";

// @ts-ignore
import ArrowForward from "../../../assets/arrow-right-black.svg";
import { nlToBr } from "@/src/helpers";

export interface IContactGrid {
  imageone: {
    sourceUrl: string;
    altText: string;
  };
  imagetwo: {
    sourceUrl: string;
    altText: string;
  };
  repeater: {
    holygrailtext: string;
  }[];
  headerone: string;
  repeatertalk: {
    letstalktext: string;
  }[];
  imagethree: {
    sourceUrl: string;
    altText: string;
  };
  contactmobileimage: {
    sourceUrl: string;
    altText: string;
  };
  firstlink: {
    url: string;
    title: string;
  };
}
const ContactGrid: React.FC<IContactGrid> = (data) => {
  return (
    <>
      <S.Grid>
        <S.GridItem>
          <S.Image src={data.imageone.sourceUrl} alt={data.imageone.altText} />
        </S.GridItem>
        <S.GridItem>
          <S.PenImage
            src={data.imagetwo.sourceUrl}
            alt={data.imagetwo.altText}
          />
        </S.GridItem>
        <S.GridItem>
          <S.Text>{nlToBr(data.repeater[0].holygrailtext)}</S.Text>
          <S.Line>/</S.Line>
          <S.Text>{nlToBr(data.repeater[1].holygrailtext)}</S.Text>
          <S.Line>/</S.Line>
          <S.Text>{nlToBr(data.repeater[2].holygrailtext)}</S.Text>
        </S.GridItem>
        <S.GridItem>
          <S.SmallBox color="#E7DBCB">
            <S.TextContainer>
              <S.Header>{data.headerone}</S.Header>
              <S.Text2>{nlToBr(data.repeatertalk[0].letstalktext)}</S.Text2>
              {data.repeatertalk[1]?.letstalktext && (
                <>
                  <S.Line>/</S.Line>
                  <S.Text>{nlToBr(data.repeatertalk[1].letstalktext)}</S.Text>
                </>
              )}
              <S.ButtonContainer>
                <S.ButtonText href={data.firstlink.url}>
                  {data.firstlink.title}
                  <S.ArrowNext src={ArrowForward} />
                </S.ButtonText>
              </S.ButtonContainer>
            </S.TextContainer>
          </S.SmallBox>
        </S.GridItem>
        <S.GridItem>
          {data.imagethree && (
            <S.SmallImage
              src={data.imagethree.sourceUrl}
              alt={data.imagethree.altText}
            />
          )}
          <S.ImageLine></S.ImageLine>
          {data.contactmobileimage && (
            <S.MobileImage
              src={data.contactmobileimage.sourceUrl}
              alt={data.contactmobileimage.altText}
            />
          )}
        </S.GridItem>
      </S.Grid>
    </>
  );
};

export default ContactGrid;
