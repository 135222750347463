import styled from "styled-components";
import { detailsBig } from "@/styles/typography";

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;
  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-gap: 20px;
    margin-top: 20px;
  }
`;

const GridItem = styled.div`
  position: relative;
  --grid-item-hover-opacity: 0;

  &:hover {
    --grid-item-hover-opacity: 1;
  }

  &:nth-of-type(1) {
    grid-column: 1 / span 2;
    grid-row: span 2;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 4;
    }
  }
  &:nth-of-type(2) {
    grid-column: 3 / span 1;
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 2;
    }
  }
  &:nth-of-type(3) {
    grid-column: 4 / span 1;
    @media only screen and (max-width: 992px) {
      grid-column: 3 / span 2;
    }
  }
  &:nth-of-type(4) {
    grid-column: 3 / span 2;
    /* grid-row: 4; */
    @media only screen and (max-width: 992px) {
      grid-column: 1 / span 4;
      grid-row: auto;
    }
  }
`;

const GridItemHover = styled(detailsBig)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(20, 24, 44, 0.61);
  opacity: var(--grid-item-hover-opacity);
  color: #ffffff;
  text-align: left;
  padding: 20px;
  transition: all 0.3s ease;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const GridImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const S = {
  Grid,
  GridItem,
  GridImage,
  GridItemHover,
  VideoContainer,
  Video,
};

export default S;
