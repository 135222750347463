import * as React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "gatsby";

// @ts-ignore
import ArrowForward from "../../../assets/arrow-right-black.svg";

import S from "./Grid.styled";
import { IImage, IVideo, ILink } from "@/src/types";
import { nlToBr } from "@/src/helpers";

type IGridItem = {
  secondGrid?: boolean;
  image: IImage;
  mobileImage?: IImage;
  video: IVideo;
  link?: {
    title: string;
    target: string;
    url: string;
  };
};

export interface IGrid {
  images: ReadonlyArray<IGridItem>;
  boxheader: string;
  boxtext: string;
  link: ILink;
  slidertext: string;
}

const GridItem: React.FC<IGridItem> = (props) => {
  return (
    <S.GridItem secondGrid={props.secondGrid}>
      <Link to={props.link?.url || "/"}>
        {props.mobileImage && (
          <S.GridImage className="mobile" src={props.mobileImage.sourceUrl} />
        )}
        {props.video ? (
          <S.VideoContainer>
            <S.Video autoPlay muted loop playsInline>
              <source
                src={props.video.mediaItemUrl}
                type={props.video.mimeType}
              />
            </S.Video>
          </S.VideoContainer>
        ) : (
          <S.GridImage className="desktop" src={props.image.sourceUrl} />
        )}
        {props.link && <S.GridItemHover>{props.link.title}</S.GridItemHover>}
      </Link>
    </S.GridItem>
  );
};

const Grid: React.FC<IGrid> = (data) => {
  return (
    <>
      <S.Grid>
        <GridItem {...data.images[0]} />
        <GridItem {...data.images[1]} />
        <GridItem {...data.images[2]} />
        <GridItem {...data.images[3]} />
        <S.GridItem>
          <S.GridItemContent>
            <S.TextContainer>
              <S.GridHeader>{data.boxheader}</S.GridHeader>
              <S.GridText>{nlToBr(data.boxtext)}</S.GridText>
              <S.ButtonContainer to={data.link.url}>
                <S.ButtonText>{data.link.title}</S.ButtonText>
                <S.ArrowNext src={ArrowForward} />
              </S.ButtonContainer>
            </S.TextContainer>
          </S.GridItemContent>
        </S.GridItem>
        <GridItem {...data.images[4]} />
        <GridItem {...data.images[5]} />
        <GridItem {...data.images[6]} />
      </S.Grid>

      <S.MessageContainer>
        <Marquee gradient={false} speed={120}>
          <S.SliderText>{data.slidertext} / </S.SliderText>
        </Marquee>
      </S.MessageContainer>

      <S.Grid secondGrid={true}>
        <GridItem {...data.images[7]} />
        <GridItem secondGrid={true} {...data.images[8]} />
        <GridItem {...data.images[9]} />
        <GridItem {...data.images[10]} />
      </S.Grid>
    </>
  );
};

export default Grid;
