import * as React from "react";

import { LocationContext } from "@/contexts/LocationProvider";

// @ts-ignore
import ArrowForward from "../../../assets/arrow-right-white.svg";
import S from "./Footer.style";
import useFooterData from "./useFooterData";
import axios from "axios";
import { IImage } from "@/src/types";

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  newsletters: string[];
};

type FormState = {
  isLoading: boolean;
  error?: string;
  data: FormData;
  success: boolean;
};

const emptyForm = (): FormData => ({
  email: "",
  firstName: "",
  lastName: "",
  newsletters: [],
});

const emptyFormState = (): FormState => ({
  isLoading: false,
  data: emptyForm(),
  success: false,
});

type CheckboxGroupProps = {
  value: string[];
  options: string[];
  onChange: (value: string[]) => void;
};

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  return (
    <>
      {props.options.map((o) => (
        <S.Label key={o}>
          <S.Input
            type="checkbox"
            onChange={(event) => {
              if (event.target.checked) {
                const newValue = props.value.slice();
                newValue.push(o);
                props.onChange(newValue);
              } else {
                props.onChange(props.value.filter((v) => v !== o));
              }
            }}
            checked={props.value.includes(o)}
          />
          {o}
        </S.Label>
      ))}
    </>
  );
};

type FooterProps = {
  mobileFooterImage?: IImage;
};

const Footer: React.FC<FooterProps> = (props) => {
  const [formState, setFormState] = React.useState(emptyFormState());
  const [formError, setFormError] = React.useState<{
    error?: boolean;
  }>({ error: false });

  const data = useFooterData();

  /**
   *
   * @param {string} property Property name
   * @param {mixed} value Value
   */
  function updateFormProperty<K extends keyof FormData>(
    property: K,
    value: FormData[K]
  ) {
    setFormState((state) => ({
      ...state,
      data: {
        ...state.data,
        [property]: value,
      },
    }));
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFormError(validate(formState.data));
  };

  const validate = (values: FormData) => {
    const errors: {
      error?: boolean;
    } = {};

    if (values.newsletters.length === 0) {
      errors.error = true;
    }
    if (!values.email) {
      errors.error = true;
    }
    if (!values.firstName) {
      errors.error = true;
    }
    if (!values.lastName) {
      errors.error = true;
    }

    if (Object.keys(errors).length === 0) {
      submitForm();
      return errors;
    } else {
      return errors;
    }
  };

  const submitForm = () => {
    setFormState((state) => ({
      ...state,
      isLoading: true,
    }));

    axios
      .post(
        (process.env.GATSBY_WP_GRAPHQL_URL || "").replace(
          "graphql",
          "wp-json/qte/v1/contact"
        ),
        {
          ...formState.data,
          newsletters: formState.data.newsletters.join(", "),
        }
      )
      .then((res) => {
        if (!res.data.success) {
          setFormState((state) => ({
            ...state,
            isLoading: false,
            error: res.data.error_msg,
          }));
        } else {
          setFormState((state) => ({
            ...state,
            isLoading: false,
            success: true,
          }));
        }
      });
  };

  return (
    <S.Footer>
      <S.MainInner>
        <S.FlexContainer>
          <S.QuoteContainer>
            <S.Header>
              {data.quotetext.split("\n").map((l, index) => (
                <React.Fragment key={index}>
                  {l}
                  <br />
                </React.Fragment>
              ))}
            </S.Header>
            <S.QuoteAuthor>{data.quoteauthor}</S.QuoteAuthor>
          </S.QuoteContainer>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.NewsLetterContainer>
            <S.Header>{data.headingone}</S.Header>
            <S.SecondHeader>Read about Us</S.SecondHeader>
            {formState.isLoading && <S.FormText>Loading...</S.FormText>}
            {!formState.isLoading && (
              <>
                {!formState.success && (
                  <>
                    {formState.error && (
                      <S.ErrorMessage>{formState.error}</S.ErrorMessage>
                    )}
                    <CheckboxGroup
                      options={["Holy Grail", "Olle Eksell"]}
                      value={formState.data.newsletters}
                      onChange={(value) =>
                        updateFormProperty("newsletters", value)
                      }
                    />
                    <S.InputField
                      type="text"
                      placeholder="First Name"
                      value={formState.data.firstName}
                      onChange={(event) =>
                        updateFormProperty("firstName", event.target.value)
                      }
                    />
                    <S.InputField
                      type="text"
                      placeholder="Last Name"
                      value={formState.data.lastName}
                      onChange={(event) =>
                        updateFormProperty("lastName", event.target.value)
                      }
                    />
                    <S.InputField
                      type="text"
                      placeholder="E-mail"
                      value={formState.data.email}
                      onChange={(event) =>
                        updateFormProperty("email", event.target.value)
                      }
                    />
                    {formError.error && (
                      <S.ErrorMessage>All fields are required</S.ErrorMessage>
                    )}
                    <S.Button onClick={handleSubmit}>
                      <S.ButtonText>Subscribe</S.ButtonText>
                      <S.ArrowNext src={ArrowForward} />
                    </S.Button>
                  </>
                )}
                {formState.success && (
                  <S.FormText>Thanks for subscribing!</S.FormText>
                )}
              </>
            )}
          </S.NewsLetterContainer>
          <S.FollowContactWrapper>
            <S.FollowContainer>
              <S.Header>{data.headingtwo}</S.Header>
              <S.SecondHeader>Connect with Us</S.SecondHeader>
              {data.sociallinks.map((social) => (
                <S.SocialLink
                  key={social.link.title}
                  href={social.link.url}
                  target={social.link.target}
                >
                  <S.Logo
                    src={social.logo.sourceUrl}
                    alt={social.logo.altText}
                  />{" "}
                  {social.link.title}
                </S.SocialLink>
              ))}
              <S.FooterImage
                src={data.imageonefooter.sourceUrl}
                alt={data.imageonefooter.altText}
              />
            </S.FollowContainer>

            <S.ContactContainer>
              <S.Header>{data.headingthree}</S.Header>
              <S.SecondHeader>Talk with Us</S.SecondHeader>
              <S.Link href={`mailto:${data.emailtext}`}>
                {data.emailtext}
              </S.Link>
              <S.Link href={`tel:${data.phonetext}`}>{data.phonetext}</S.Link>
              <S.FooterImage
                src={data.imagetwofooter.sourceUrl}
                alt={data.imagetwofooter.altText}
              />
            </S.ContactContainer>
          </S.FollowContactWrapper>

          <S.MobileFooterImage
            src={
              props.mobileFooterImage
                ? props.mobileFooterImage.sourceUrl
                : data.imageonefooter.sourceUrl
            }
            alt={
              props.mobileFooterImage
                ? props.mobileFooterImage.altText
                : data.imageonefooter.altText
            }
          />
        </S.FlexContainer>
      </S.MainInner>
    </S.Footer>
  );
};

export default Footer;
