import "swiper/css";
import "swiper/css/navigation";

import * as React from "react";
import { useRef } from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import S from "./TestiMonial.styled";

SwiperCore.use([Navigation]);

export type ITestiMonial = {
  header: string;
  testimonials: {
    image: {
      sourceUrl: string;
      altText: string;
    };
    openingwords: string;
    paragraph: string;
    author: string;
    chapterheader: string;
  }[];
};

const TestiMonial: React.FC<ITestiMonial> = (data: ITestiMonial) => {
  const testimonials = [...data.testimonials];
  const swiperRef = React.useRef<SwiperCore>();

  const onInit = (Swiper: SwiperCore): void => {
    swiperRef.current = Swiper;
  };
  const handleMouseEnter = () => {
    if (swiperRef.current) swiperRef.current.autoplay.stop();
  };
  const handleMouseLeave = () => {
    if (swiperRef.current) swiperRef.current.autoplay.start();
  };

  return (
    <S.TestiMonials>
      <S.MainInner>
        <S.InnerDiv>
          <S.Header>{data.header}</S.Header>
        </S.InnerDiv>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Swiper
            modules={[Navigation, Autoplay]}
            centeredSlides={true}
            slidesPerView="auto"
            loop={true}
            autoplay={true}
            className="mySwiper"
            navigation={{
              nextEl: ".testimonial-next-button",
              prevEl: ".testimonial-prev-button",
            }}
            onInit={onInit}
          >
            {testimonials.map(
              (
                { image, openingwords, paragraph, author, chapterheader },
                index
              ) => (
                <SwiperSlide key={index}>
                  <S.SwiperContent>
                    <S.Content>
                      <S.Image src={image.sourceUrl} />
                      <S.Author>{author}</S.Author>
                      <S.Chapter>{chapterheader}</S.Chapter>
                    </S.Content>
                    <S.TestiMonial>
                      <S.Intro>{openingwords}</S.Intro>
                      {paragraph}
                    </S.TestiMonial>
                  </S.SwiperContent>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
        <S.PrevButton
          className="testimonial-prev-button"
          width="104"
          height="207"
          viewBox="0 0 104 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M103.5 206L1.00001 103.5L103.5 1"
            stroke="black"
            strokeWidth="1.33"
          />
        </S.PrevButton>
        <S.NextButton
          className="testimonial-next-button"
          width="105"
          height="207"
          viewBox="0 0 105 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L103.5 103.5L1 206" stroke="black" strokeWidth="1.33" />
        </S.NextButton>
      </S.MainInner>
    </S.TestiMonials>
  );
};

export default TestiMonial;
