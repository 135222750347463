import {
  detailsBig,
  detailsSmall,
  h3,
  paragraphBig,
} from "@/styles/typography";
import { Link } from "gatsby";
import styled from "styled-components";

const Grid = styled.div<{ secondGrid?: boolean }>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: calc(50vw - 10px);
  margin-bottom: ${(props) => (props.secondGrid ? "10px" : "0")};
  @media ${({ theme }) => theme.breakpoints.xs} {
    grid-gap: 20px;
    margin-bottom: ${(props) => (props.secondGrid ? "20px" : "0")};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    grid-template-rows: calc(36vw - 10px) calc(25vw - 10px);
  }
  &:nth-of-type(2) {
    background: blue;
  }
`;

const GridItem = styled.div<{ secondGrid?: boolean }>`
  position: relative;
  overflow: hidden;
  --grid-item-hover-opacity: 0;

  &:hover {
    --grid-item-hover-opacity: 1;
  }

  &:nth-of-type(1) {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    @media ${({ theme }) => theme.breakpoints.md} {
      grid-row: 1;
      grid-column: 1 / span 4;
    }
  }
  &:nth-of-type(2) {
    grid-row: 4 / span 2;
    grid-column: 1 / span 2;
    @media ${({ theme }) => theme.breakpoints.md} {
      grid-column: 1 / span 2;
      grid-row: span ${(props) => (props.secondGrid ? 1 : 2)};
    }
  }
  &:nth-of-type(3) {
    grid-column: 1 / span 1;

    @media ${({ theme }) => theme.breakpoints.md} {
      grid-column: 3 / span 1;
    }
  }
  &:nth-of-type(4) {
    grid-column: 2 / span 1;
    @media ${({ theme }) => theme.breakpoints.md} {
      grid-column: 4 / span 1;
    }
  }
  &:nth-of-type(5) {
    grid-column: span 2;
    display: none;
    @media ${({ theme }) => theme.breakpoints.md} {
      display: block;
    }
  }
  &:nth-of-type(6) {
    grid-row: 7 / span 2;
    grid-column: 1 / span 2;

    @media ${({ theme }) => theme.breakpoints.md} {
      grid-row: 4;
      grid-column: span 2;
    }
  }
`;

const GridImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 680px) {
    & + .desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
`;

const GridItemHover = styled(detailsBig)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(20, 24, 44, 0.61);
  opacity: var(--grid-item-hover-opacity);
  color: #ffffff;
  text-align: left;
  padding: 20px;
  transition: all 0.3s ease;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const GridItemContent = styled.div`
  background-color: ${(props) => props.theme.factColors.tertiary};
  width: 100%;
  height: 100%;
  padding: 40px;
`;

const GridHeader = styled(h3)`
  text-transform: uppercase;
  margin-bottom: 7px;
`;

const GridText = styled(paragraphBig)`
  margin-bottom: 15px;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ButtonContainer = styled(Link)`
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  color: ${(props) => props.theme.textColors.primary};
  border: 1px solid ${(props) => props.theme.textColors.primary};
  background-color: transparent;
  margin: auto auto 0;
  display: flex;
  align-items: center;
  padding: 13px 24px;
  text-decoration: none;
  &:hover {
    img {
      transform: translateX(6px);
    }
  }
`;
const ButtonText = styled(detailsSmall)``;

const ArrowNext = styled.img`
  margin-left: 10px;
  transition: all 0.2s ease;
`;

const MessageContainer = styled.div`
  background-color: ${(props) => props.theme.factColors.tertiary};
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 20px 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const SliderText = styled.p`
  font-family: Eksell, Georgia, serif;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  padding: 5px 0;
  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 40px;
    padding: 15px 0;
  }
`;

const S = {
  Grid,
  GridImage,
  GridItem,
  GridItemHover,
  GridItemContent,
  GridHeader,
  GridText,
  VideoContainer,
  Video,
  ButtonContainer,
  ButtonText,
  MessageContainer,
  SliderText,
  TextContainer,
  ArrowNext,
};

export default S;
