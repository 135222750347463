import { Link } from "gatsby";
import styled from "styled-components";
import CaseArrow from "../../../icons/case-arrow.svg";

const Container = styled.div`
  width: 100%;
`;
const Cases = styled(Link)`
  padding: 46px 20px;
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  transition: all 1s ease;
  background-position: center;
  background-size: cover;
  --link-text-color: ${(props) => props.theme.textColors.primary};
  --grid-overlay: 0;

  &:nth-of-type(4n + 1) {
    background-color: #faf7cb;
  }

  &:nth-of-type(4n + 2) {
    background-color: #fef4ec;
  }

  &:nth-of-type(4n + 3) {
    background-color: #f7e2d3;
  }

  &:nth-of-type(4n) {
    background-color: #d9e7cd;
  }

  &:nth-of-type(4n + 7) {
    background-color: #fef4ec;
  }

  &:nth-of-type(4n + 6) {
    background-color: ${(props) => props.theme.factColors.tertiary};
  }

  &:hover {
    --grid-overlay: 1;
    --link-text-color: ${(props) => props.theme.textColors.secondary};
  }

  &:hover:after {
    content: "";
    display: block;
    width: 50px;
    height: 24px;
    position: absolute;
    right: 102px;
    top: 66px;

    @media ${({ theme }) => theme.breakpoints.sm} {
      background: url(${CaseArrow});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      animation-name: arrow;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-timing-function: ease;
    }
  }

  @keyframes arrow {
    10% {
      transform: 0;
    }
    50% {
      transform: translate(10px);
    }
    90% {
      transform: 0;
    }
  }
`;

const Overlay = styled.div<{ imageUrl: string }>`
  position: absolute;
  background: #0000ffd9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
      0deg,
      rgba(20, 24, 44, 0.6),
      rgba(20, 24, 44, 0.6)
    ),
    url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: var(--grid-overlay);
  transition: all 0.4s ease;
`;

const LinkText = styled.span`
  font-family: "Eksell", "Sans-Serif";
  font-size: 24px;
  color: var(--link-text-color);
  position: relative;
  @media ${({ theme }) => theme.breakpoints.xs} {
    font-size: 40px;
  }
`;

const S = {
  Cases,
  Container,
  Overlay,
  LinkText,
};

export default S;
