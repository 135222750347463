import React from "react";

import S from "./MainHero.style";

export interface IMainHero {
  headingrowone: string;
  headingrowtwo: string;
  headingrowone2: string;
  headingrowtwo2: string;
  penimage: {
    sourceUrl: string;
    altText: string;
  };
  contenttext: string;
  circleimage: {
    sourceUrl: string;
    altText: string;
  };
  headingrowthree: string;
  smalltext: string;
  lineimage: {
    sourceUrl: string;
    altText: string;
  };
  firstlinktext: string;
  firstlink: {
    url: string;
    title: string;
    target: string;
  };
  secondlinktext: string;
  secondlink: {
    url: string;
    title: string;
    target: string;
  };
  heartimage: {
    sourceUrl: string;
    altText: string;
  };
  slogantext: string;
  wysiwyg: string;
}

const MainHero: React.FC<IMainHero> = (data) => {
  return (
    <>
      <S.MainHero>
        <S.MainInner>
          <S.ContainerText>
            {data.penimage && (
              <>
                <S.HeadingRowOne>{data.headingrowone}</S.HeadingRowOne>
                {data.penimage && (
                  <S.PenImage
                    src={data.penimage.sourceUrl}
                    alt={data.penimage.altText}
                  />
                )}
              </>
            )}
            {data.heartimage && (
              <>
                <S.HeadingRowOne>{data.headingrowone}</S.HeadingRowOne>
                <S.HeartImage
                  src={data.heartimage.sourceUrl}
                  alt={data.heartimage.altText}
                />
              </>
            )}
            {data.circleimage && (
              <>
                <S.HeadingRowOneAnimate>
                  {data.headingrowone}
                </S.HeadingRowOneAnimate>
                <S.HeadingRowOneAnimateCopy>
                  {data.headingrowone2}
                </S.HeadingRowOneAnimateCopy>
                <S.CircleHeaderContainer>
                  {data.circleimage && (
                    <S.TextContainer>
                      <S.BlackHeaderOne>{data.headingrowtwo}</S.BlackHeaderOne>
                      <S.BlackHeaderOneCopy>
                        {data.headingrowtwo2}
                      </S.BlackHeaderOneCopy>
                      {data.circleimage && (
                        <S.CircleHeaderContainer>
                          <S.CircleImage
                            src={data.circleimage.sourceUrl}
                            alt={data.circleimage.altText}
                          />
                          <S.BlackHeaderTwo>
                            {data.headingrowthree}
                          </S.BlackHeaderTwo>
                        </S.CircleHeaderContainer>
                      )}
                    </S.TextContainer>
                  )}
                </S.CircleHeaderContainer>
              </>
            )}
            {data.smalltext && <S.SmallText>{data.smalltext}</S.SmallText>}
            {data.lineimage && (
              <S.LineImage
                src={data.lineimage.sourceUrl}
                alt={data.lineimage.altText}
              />
            )}
          </S.ContainerText>
        </S.MainInner>
      </S.MainHero>

      {data.firstlink && (
        <S.LinkTextContainer>
          <S.LinkText>
            {data.firstlinktext}{" "}
            <S.Link href={data.firstlink.url} target={data.firstlink.target}>
              {data.firstlink.title}
            </S.Link>
            {data.secondlinktext}
            <S.Link href={data.secondlink.url} target={data.secondlink.target}>
              {data.secondlink.title}
            </S.Link>
          </S.LinkText>
        </S.LinkTextContainer>
      )}
      {data.contenttext && (
        <S.LinkTextContainer>
          <S.LinkText>{data.contenttext}</S.LinkText>
        </S.LinkTextContainer>
      )}
      {data.slogantext && (
        <S.LinkTextContainer>
          <S.SloganText>{data.slogantext}</S.SloganText>
        </S.LinkTextContainer>
      )}
      {data.wysiwyg && (
        <S.LinkTextContainer>
          <S.MobileText
            dangerouslySetInnerHTML={{ __html: data.wysiwyg }}
          ></S.MobileText>
        </S.LinkTextContainer>
      )}
    </>
  );
};

export default MainHero;
