import { DefaultTheme } from "styled-components";

export const primary: DefaultTheme = {
  backgroundColors: {
    primary: "#FFF9F4",
    secondary: "#B28B73",
  },
  colors: {
    primary: "#14182E",
    secondary: "#B28B73",
  },
  textColors: {
    primary: "#14182E",
    secondary: "#fef4ec",
  },
  factColors: {
    primary: "#FAF7CB",
    secondary: "#F7E2D3",
    tertiary: "#E7DBCB",
  },
  breakpoints: {
    xs: "(min-width: 480px)",
    sm: "(min-width: 680px)",
    md: "(min-width: 992px)",
    lg: "(min-width: 1430px)",
    xl: "(min-width: 1650px)",
    xxl: "(min-width: 2000px)",
  },
  margins: {
    grid: "20px 0",
    section: "90px 0",
    inner: "0 100px",
  },
};
