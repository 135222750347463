import styled from "styled-components";

const Main = styled.main`
  width: 100%;
`;

const S = {
  Main,
};

export default S;
