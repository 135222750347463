import styled from "styled-components";

const Grid = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 60px 0;
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding: 100px 0;
  }
`;

const MainInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 41px;
  grid-column-gap: 30px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding: 0 40px;
    grid-row-gap: 110px;
    grid-column-gap: 50px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 100px;
    grid-column-gap: 30px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  object-fit: contain;
  max-height: 40px;
  max-width: 100%;
  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 170px;
    max-height: 100px;
  }
`;

const S = {
  Grid,
  MainInner,
  Item,
  Image,
};

export default S;
